import React, { useEffect, useState } from "react";
import classNames from "./cookie-setting.module.scss";
import Modal from "react-modal";
import CloseIcon from "../close-icon";
import CheckMarkIcon from "../../icons/cookie/check-mark-icon";
import Button from "../button";
import Switch from "react-switch";
import { ACCEPTED_COOKIES } from "../../constants/homepage";
import { cookiePolicyAgreement } from "../../services/acceptedCookie";
import { PRIVACY_POLICY } from "../../constants/homepage";
function CookieSetting({
  setIsAcceptedCookie,
  title,
  body,
  isOpen = false,
  onToggle = () => {},
  onClose = () => {},
  onOpen = () => {},
}) {
  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);
  const [modalIsOpen, setIsOpen] = React.useState(isOpen);
  const [checked, setChecked] = useState(true);
  const [isAgreeWithCookie, setIsAgreeWithcookie] = useState(ACCEPTED_COOKIES);
  function afterOpenModal() {}

  function closeModal() {
    onClose();
  }

  const onchecked = () => {
    setChecked(!checked);
    setIsAgreeWithcookie(!ACCEPTED_COOKIES);
  };

  const onSubmitSetting = () => {
    cookiePolicyAgreement(isAgreeWithCookie);
    onClose();
    setIsAcceptedCookie(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      overlayClassName={classNames.modalWindowOverlay}
      className={classNames.modalWindow}
    >
      <div className={classNames.close}>
        <CloseIcon className={classNames.closeButton} onClick={closeModal} />
      </div>
      <div className={classNames.header}>
        <h3>Nastavenia cookies</h3>

        <div className={classNames.title}>
          <p className={classNames.innerGroupTitle}>Nevyhnutné cookies</p>
          <div className={classNames.check}>
            <CheckMarkIcon className={classNames.icon} />
            <p className={classNames.iconTitle}>Zapnuté</p>
          </div>
        </div>
        <p className={classNames.description}>
          Nevyhnutné cookies sú potrebné pre fungovanie webu.
        </p>
        <div className={classNames.title}>
          <p className={classNames.innerGroupTitle}>Ostatné cookies</p>
          <div className={classNames.check}>
            <Switch
              onChange={onchecked}
              checked={checked}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={12}
              onHandleColor={"#000000"}
              offHandleColor={"#fff"}
              onColor={"#fff"}
              offColor={"#000000"}
              width={30}
              height={14}
              className={classNames.toogleButton}
            />
            <p className={classNames.iconTitle}>Zapnuté</p>
          </div>
        </div>
        <p className={classNames.lastDescription}>
          Ostatné cookies sú cookies, ktoré sú používané predovšetkým za účelom
          zvýšenia užívateľského komfortu, ponúknutia reklamy podľa záujmov
          užívateľa, zostavovania anonymných štatistík o využívaní webstránky.
        </p>

        <div className={classNames.contentTwo}>
          <div className={classNames.txt}>
            <p>
              <a href={PRIVACY_POLICY} className={classNames.Privecylink} target={'_blank'}>
                Pravidlá súkromia
              </a>
            </p>
          </div>
          <div className={classNames.btn}>
            <Button color="green" onClick={onSubmitSetting}>
              ULOŽIŤ NASTAVENIA
            </Button>
          </div>
        </div>
      </div>
      <div className={classNames.body}>{body}</div>
    </Modal>
  );
}

export default CookieSetting;
