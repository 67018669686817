import { setToCookie, getFromCookie } from './cookie.service'
import {KEY_ACCEPTED_COOKIES} from "../constants/homepage"

export const cookiePolicyAgreement = (acceptetCookie) => {
   setToCookie(KEY_ACCEPTED_COOKIES, JSON.stringify(acceptetCookie))
}

export const getAcceptedCookie = (key) =>{
   const cookieData = getFromCookie(key)
   try {
      return JSON.parse(cookieData)
  } catch (error) {
      return cookieData
  }
}