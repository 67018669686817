import { CookieStorage } from 'cookie-storage'

export const setToCookie = (key, value) => {
    const storage = new CookieStorage({
        path: "/",
    }
     
    )
    storage.setItem(key, value)
}

export const getFromCookie = (key) => {
    const storage = new CookieStorage();
    return storage.getItem(key)
}
